import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    appUrl: process.env.VUE_APP_URL,
    cdnUrl: process.env.VUE_APP_CDN_URL,
    manageUrl: process.env.VUE_APP_MANAGE_URL,
    docsUrl: process.env.VUE_APP_DOCS_URL
  },
  mutations,
  actions: {
  },
  modules: {
  }
})
