import { SAVE_USERINFO } from '@/store/mutation-types'
import { setStore } from '@/config/mUtils'
export default {
  [SAVE_USERINFO] (state, info) {
    state.userinfo = info
    state.userid = info.userid
    state.username = info.ushowname
    state.login = true
    setStore('userid', info.userid)
    setStore('username', info.ushowname)
    setStore('userinfo', info)
  }
}
