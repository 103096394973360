<template>
  <div id="app" style="min-width: 1300px;width: 100%">
    <!--    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <router-view style="width: 100%;min-width: 1300px"/>
  </div>
</template>
<script>
export default {
  created () {
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('messageStore', JSON.stringify(this.$store.state))
    })
    // 在页面加载时读取localStorage里的状态信息
    localStorage.getItem('messageStore') && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem('messageStore'))))
  }

}
</script>
<style lang="less">
html{
  font-size: 12px;
}
*{
  font-family: Microsoft YaHei;
  padding: 0;
  margin: 0;
}
/*!*当页面宽度大于1000px且小于1200px的时候执行，1000-1200*!
@media screen and (max-width: 1200px){
  html{
    font-size:12px
  }
}
!*当页面宽度大于1280px且小于1366px的时候执行,1280-1366*!
@media screen and (min-width:1280px) and (max-width: 1366px){
  html{
    font-size:12px
  }
}
!*当页面宽度大于1440px且小于1600px的时候执行,1440-1600*!
@media screen and (min-width:1440px) and (max-width:1600px){
  html{
    font-size:12px
  }
}
!*当页面宽度大于1680px且小于1920px的时候执行,1680-1920*!
@media screen and (min-width:1680px) and (max-width:1920px){
  html{
    font-size:12px
  }
}*/
</style>
