import Vue from 'vue'
import VueRouter from 'vue-router'
const Home = r => require.ensure([], () => r(require('../views/Home.vue')), 'home')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('../views/ActivityDetail.vue')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
