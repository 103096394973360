import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery' // 加上这句话
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// 1.全局引用
import videoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
Vue.use(videoPlayer)
// Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$ = $
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
